import isBrowser from "./isBrowser";

export const restoreObjectFromSessionStorage = (sessionStorageKey) => (isBrowser()) ? JSON.parse(sessionStorage?.getItem(sessionStorageKey) ?? '{}') : {} ;
export const restoreStringFromSessionStorage = (sessionStorageKey) => (isBrowser()) ? (sessionStorage?.getItem(sessionStorageKey) ?? '') :  '';

export const setCreditId =  () => {
    let flujo = restoreObjectFromSessionStorage('flujo')

    let urlParams = new URLSearchParams(window.location.search)
    if(urlParams.has('creditApplicationId') && urlParams.get('creditApplicationId') !== flujo.creditApplicationId) {
        const id = urlParams.get('creditApplicationId')
        flujo = {
            ...flujo, 
            creditApplicationId: id,
        }
        sessionStorage.setItem('flujo', JSON.stringify(flujo))
    }

    return flujo
}